import * as THREE from "three";
import Experience from "../Experience.js";
import Interlocutors from "../World/Interlocutors.js";

export default class Networking {
  constructor() {
    this.experience = new Experience();
    this.user = this.experience.user;
    this.canSendEmbodiment = false;
    this.interlocutors = new Interlocutors();

    // First, fetch username and color from the server
    this.initializeUser()
      .then(({ username, color }) => {
        this.user.parameters.userName = username;
        this.user.parameters.color = color;

        console.log(`Received username: ${username}, color: ${color}`);

        // Now create the WebSocket connection
        this.socket = new WebSocket("wss://brahma.xrss.org:8080");

        this.socket.onopen = () => {
          console.log("WebSocket connection established");
          this.sendInitialData(); // Send initial data when the connection opens
          // Enable embodiment sending after 1 second
          setTimeout(() => {
            this.canSendEmbodiment = true;
          }, 1000);
        };

        this.socket.onmessage = (event) => {
          if (event.data.length > 1) {
            this.receiveEmbodiments(event.data);
          }
        };

        this.socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          console.log("ReadyState:", this.socket.readyState);
        };

        this.socket.onclose = (event) => {
          console.log("WebSocket connection closed:", event.reason);
        };
      })
      .catch((error) => {
        console.error("Error initializing user:", error);
      });
  }

  // Method to fetch username and color from the server
  async initializeUser() {
    try {
      const response = await fetch(
        "https://brahma.xrss.org:8080/uniqueUsernameAndColor"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch username and color");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
  }

  sendInitialData() {
    const initData = {
      name: this.user.parameters.userName,
      color: this.user.parameters.color,
    };

    // Send the initial user data as a string over WebSocket
    this.socket.send(JSON.stringify(initData));
    console.log("Initial user data sent:", initData);
  }

  sendEmbodiment(HMD, LController, RController, callout = 0) {
    const data = {
      name: this.user.parameters.userName,
      color: this.user.parameters.color,
      HMDPosition: HMD.toArray(),
      LController: LController.toArray(),
      RController: RController.toArray(),
      callout: callout,
    };

    // Send the data as a string over WebSocket
    if (this.canSendEmbodiment) {
      this.socket.send(JSON.stringify(data));
    } else {
      console.log("Cannot send embodiment data yet");
    }
  }
  // function to convert a string "0x000000" to a number that would be a good input for a three js color in a material

  receiveEmbodiments(data) {
    // console.log("Raw data received:", data);

    try {
      const interlocutorsData = JSON.parse(data);
      // console.log("Received interlocutor data:", interlocutorsData);

      interlocutorsData.forEach((interlocutor) => {
        try {
          if (interlocutor.name === this.user.parameters.userName) {
            return;
          }

          if (!this.interlocutors.containsEmbodiment(interlocutor.name)) {
            this.interlocutors.instantiateEmbodiment(
              interlocutor.name,
              new THREE.Color(interlocutor?.color)
            );
          }

          if (this.interlocutors.containsEmbodiment(interlocutor.name)) {
            const HMDMatrix = new THREE.Matrix4().fromArray(
              interlocutor.HMDPosition
            );
            const LControllerMatrix = new THREE.Matrix4().fromArray(
              interlocutor.LController
            );
            const RControllerMatrix = new THREE.Matrix4().fromArray(
              interlocutor.RController
            );

            this.interlocutors.updateEmbodiment(
              interlocutor.name,
              HMDMatrix,
              LControllerMatrix,
              RControllerMatrix
            );
          }
        } catch (error) {
          console.error("Error processing interlocutor data:", error);
        }
      });
    } catch (error) {
      console.error("Error parsing interlocutor data:", error);
    }
  }
}
