import * as THREE from "three";
import Experience from "../Experience";
import Raycastable from "./Raycastable";
export default class Calloutable extends Raycastable {
  constructor(geometry, material, name = "not named", color = 0xff0000) {
    super(geometry, material, name, color);
    this.calloutable = true;
    this.active = false;
    this.calloutDisplay = new THREE.Group();
    this.calloutDisplay.visible = false;
    this.makeCalloutDisplay();
    this.scene = this.experience.scene;
    this.scene.add(this.calloutDisplay);
  }
  raycastEnter() {
    this.hover = true;
    if (!this.active) {
      this.material.color.set(0xffff00);
      this.calloutDisplay.visible = true;
      this.updateCalloutDisplayPosition();
    }
  }
  raycastExit() {
    this.hover = false;
    if (!this.active) {
      this.material.color.set(this.OGColor);
      this.calloutDisplay.visible = false;
    }
  }
  activate() {
    this.active = true;
    this.material.color.set(0x00ff00);
    this.calloutDisplay.visible = true;
    this.experience.raycastableObjects.forEach((object) => {
      if (object.calloutable && object !== this) {
        if (object.active) {
          object.deactivate();
        }
      }
    });
    this.updateCalloutDisplayPosition();
  }
  deactivate() {
    this.active = false;
    this.material.color.set(this.OGColor);
    this.calloutDisplay.visible = false;
  }
  trigger() {
    if (this.active) {
      this.deactivate();
    } else {
      this.activate();
    }
  }
  // makeCalloutDisplay() {
  //   const calloutPlaneGeometry = new THREE.PlaneGeometry(0.5, 0.3);
  //   const calloutPlaneMaterial = new THREE.MeshBasicMaterial({
  //     color: 0xffffff,
  //     side: THREE.DoubleSide,
  //   });
  //   this.calloutPlane = new THREE.Mesh(
  //     calloutPlaneGeometry,
  //     calloutPlaneMaterial
  //   );
  //   const position = new THREE.Vector3();
  //   position.copy(this.position);
  //   this.calloutPlane.position.set(position.x, position.y + 0.5, position.z);
  //   this.calloutDisplay.add(this.calloutPlane);
  // }
  makeCalloutDisplay() {
    // Create a canvas element to draw the text on it
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.width = 512;
    canvas.height = 256;

    // Set background color to gray
    context.fillStyle = "#808080"; // gray color
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Set text style
    context.font = "40px Arial";
    context.fillStyle = "#ffffff"; // white text color
    context.textAlign = "center";
    context.textBaseline = "middle";

    // Draw text (name) in the center of the canvas
    context.fillText(this.name, canvas.width / 2, canvas.height / 2);

    // Create a texture from the canvas
    const texture = new THREE.CanvasTexture(canvas);

    // Create a material using the texture
    const calloutPlaneMaterial = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
    });

    // Create geometry and mesh for the plane
    const calloutPlaneGeometry = new THREE.PlaneGeometry(0.5, 0.3);
    this.calloutPlane = new THREE.Mesh(
      calloutPlaneGeometry,
      calloutPlaneMaterial
    );

    // Set the position for the callout plane
    const position = new THREE.Vector3();
    position.copy(this.position);
    this.calloutPlane.position.set(position.x, position.y + 0.5, position.z);
    this.calloutPlane.scale.set(0.8, 0.8, 0.8);
    // Add the plane to the callout display group
    this.calloutDisplay.add(this.calloutPlane);
    // add a thin line to connect the callout to the object
    const lineGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([
      0,
      0,
      0, // starting point
      0,
      0.5,
      0, // ending point
    ]);
    lineGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(vertices, 3)
    );

    const lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
    const line = new THREE.Line(lineGeometry, lineMaterial);
    this.calloutDisplay.add(line);
  }
  updateCalloutDisplayPosition() {
    this.calloutPlane.position.set(
      this.position.x,
      this.position.y + 0.5,
      this.position.z
    );
    this.calloutPlane.lookAt(
      !this.experience.isXRActive()
        ? this.experience.camera.instance.position
        : this.experience.cameraGroup.position
    );
  }
}
