import * as THREE from "three";
import Debug from "./Utils/Debug.js";
import Sizes from "./Utils/Sizes.js";
import Time from "./Utils/Time.js";
import Resources from "./Utils/Resources.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import World from "./World/World.js";
import sources from "./sources.js";
import { VRButton } from "three/examples/jsm/webxr/VRButton.js";
import Controller from "./Utils/Controller/Controller.js";
import User from "./User.js";
import Networking from "./Utils/Networking.js";
import RaycasterHandler from "./Utils/RaycastHandler.js";

let instance = null;

export default class Experience {
  constructor(canvas) {
    // Singleton pattern
    if (instance) {
      return instance;
    }
    instance = this;
    window.experience = this;

    this.canvas = canvas;
    this.debug = new Debug();
    this.user = new User();
    /* Raycaster Code */
    this.raycastableObjects = [];
    /* Grabbing Code */
    this.grabbableObjects = [];

    this.raycaster = new THREE.Raycaster();
    this.raycasterHandler = new RaycasterHandler(this.raycaster);
    this.mouse = new THREE.Vector2();

    const sizes = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    window.addEventListener("mousemove", (event) => {
      this.mouse.x = (event.clientX / sizes.width) * 2 - 1;
      this.mouse.y = -(event.clientY / sizes.height) * 2 + 1;
      1;
    });

    window.addEventListener("click", () => {
      this.raycasterHandler.activateCurrentIntersect();
    });

    if (this.debug.active) {
      // this.debugFolder = this.debug.ui.addFolder("experience");
      this.debug.ui
        .add(
          {
            initNetworking: () => {
              window.experience.networking = new Networking();
            },
          },
          "initNetworking"
        )
        .name("Join Session");
      // add a button that does     this.networking = new Networking();
    }

    this.sizes = new Sizes();
    this.time = new Time();
    this.scene = new THREE.Scene();
    console.log("sources", sources);
    this.resources = new Resources(sources);
    this.world = new World();
    this.cameraGroup = new THREE.Group();

    this.camera = new Camera();
    this.renderer = new Renderer();

    /** XR/Immersive Code */
    this.scene.add(this.cameraGroup);
    this.controller = new Controller();
    this.renderer.instance.xr.enabled = true;
    document.body.appendChild(VRButton.createButton(this.renderer.instance));
    this.renderer.instance.setAnimationLoop(() => {
      this.controller.update();
      this.renderer.instance.render(this.scene, this.camera.instance);
    });

    this.sizes.on("resize", () => {
      this.resize();
      this.camera.resize();
      this.renderer.resize();
    });
    this.time.on("tick", () => {
      this.update();
    });

    // this.setupLoginPanel();
  }

  resize() {
    console.log("resized occured");
    this.camera.resize();
  }

  update() {
    this.camera.update();
    if (!this.isXRActive()) {
      this.raycaster.setFromCamera(this.mouse, this.camera.instance);
      this.raycasterHandler.handleRaycast();
      if (this.networking?.cansSendEmbodiment) {
        this.networking.sendEmbodiment(
          this.camera.instance.matrixWorld,
          this.camera.instance.matrixWorld,
          this.camera.instance.matrixWorld
        );
      }
    } else {
      if (this.networking?.cansSendEmbodiment) {
        this.networking.sendEmbodiment(
          this.camera.instance.matrixWorld,
          this.controller.controller1.matrixWorld,
          this.controller.controller2.matrixWorld
        );
      }
    }
    this.world.update();
  }
  isXRActive() {
    return this.renderer.instance.xr.isPresenting;
  }
  // setupLoginPanel() {
  //   // Generate random username
  //   const randomUsernames = [
  //     "User-A",
  //     "User-B",
  //     "User-C",
  //     "User-D",
  //     "User-E",
  //     "User-F",
  //     "User-G",
  //     "User-H",
  //     "User-I",
  //     "User-J",
  //     "User-K",
  //     "User-L",
  //     "User-M",
  //     "User-N",
  //     "User-O",
  //     "User-P",
  //     "User-Q",
  //     "User-R",
  //     "User-S",
  //     "User-T",
  //     "User-U",
  //     "User-V",
  //     "User-W",
  //     "User-X",
  //     "User-Y",
  //     "User-Z",
  //   ];
  //   document.getElementById("username").value =
  //     randomUsernames[Math.floor(Math.random() * randomUsernames.length)];

  //   // Clear input on focus
  //   const usernameInput = document.getElementById("username");
  //   usernameInput.addEventListener("focus", function () {
  //     if (this.value.startsWith("User-")) {
  //       this.value = "";
  //     }
  //   });

  //   // Select a random color
  //   const colors = document.querySelectorAll(".color-option");

  //   // Ensure no colors are selected initially
  //   colors.forEach((c) => c.classList.remove("selected"));

  //   // Select a single random color
  //   const randomColorIndex = Math.floor(Math.random() * colors.length);
  //   colors[randomColorIndex].classList.add("selected");

  //   colors.forEach((color) => {
  //     color.addEventListener("click", () => {
  //       colors.forEach((c) => c.classList.remove("selected"));
  //       color.classList.add("selected");
  //     });
  //   });

  //   function login() {
  //     const username = document.getElementById("username").value;
  //     const selectedColorClass = document.querySelector(
  //       ".color-option.selected"
  //     ).classList[1];

  //     // Mapping the class names to hex color codes
  //     const colorMap = {
  //       "pastel-blue": "#a2d2ff",
  //       "pastel-pink": "#ffafcc",
  //       "pastel-green": "#caffbf",
  //       "pastel-yellow": "#ffd6a5",
  //       "pastel-purple": "#bdb2ff",
  //       "pastel-orange": "#ffadad",
  //     };

  //     const selectedColorHex = colorMap[selectedColorClass];
  //     const selectedColor = parseInt(selectedColorHex.replace("#", "0x"), 16); // Convert hex to 0x format integer

  //     console.log(
  //       `Logging in with username: ${username} and color: ${selectedColorHex}`
  //     );
  //     this.user.parameters.userName = username;
  //     this.user.parameters.color = selectedColor; // Now in 0x format for THREE.js
  //     window.experience.networking = new Networking();

  //     document.getElementById("VRButton").click();
  //   }

  //   // select the login panel, have the onclick be login
  //   document.getElementById("login-button").onclick = login.bind(this);
  // }
  destroy() {
    this.sizes.off("resize");
    this.time.off("tick");

    this.scene.traverse((child) => {
      // Test if it's a mesh
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();
        // Loop through the material properties
        for (const key in child.material) {
          const value = child.material[key];

          // Test if there is a dispose function
          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });
    this.camera.controls.dispose();
    this.renderer.instance.dispose();
    if (this.debug.active) {
      this.debug.ui.destroy();
    }
  }
}
