import * as THREE from "three";
import Experience from "../Experience.js";

export default class Environment {
  constructor() {
    // include lights, include skybox, include fog
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    // this.debug = this.experience.debug;
    this.parameter = {
      color: 0x000000,
    };
    if (this.debug?.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
      const debugObject = {
        color: this.parameter.color,
      };
      this.debugFolder.addColor(debugObject, "color").onChange((value) => {
        this.scene.background.set(value);
      });
    }
    this.scene.background = new THREE.Color(this.parameter.color);
    this.setAmbientLight();
    // add a hemisphere light
    this.hemiLight = new THREE.HemisphereLight("#ffffff", "#000000", 0.2);
    this.scene.add(this.hemiLight);
    // add a directional light
    this.dirLight = new THREE.DirectionalLight("#ffffff", 2.0);
    this.dirLight.position.set(5, 5, 5);
    this.scene.add(this.dirLight);
  }
  setAmbientLight() {
    this.ambientLight = new THREE.AmbientLight("#ffffff", 0.6);
    this.scene.add(this.ambientLight);
  }
}
