import * as THREE from "three";
import Experience from "../Experience";

export default class Raycastable extends THREE.Mesh {
  constructor(geometry, material, name = "not named", color = 0xff0000) {
    super(geometry, material);
    this.OGColor = color;
    this.name = name;
    this.experience = new Experience();
    this.experience.raycastableObjects.push(this);
    this.hover = false;
    this.raycastable = true;
  }

  raycastEnter() {
    this.hover = true;
    this.material.color.set(0xffff00);
    console.log(this.material.color);
    console.log(this.name + " hovered");
  }
  raycastExit() {
    this.hover = false;
    this.material.color.set(this.OGColor);
    console.log(this.name + " exited");
    // this.calloutPlane.display = false;
  }
  trigger() {
    console.log(this.name + triggered);
  }
  dispose() {
    //TODO test
    this.experience.raycastableObjects =
      this.experience.raycastableObjects.filter((object) => object !== this);
  }
}
