import * as THREE from "three";
import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Floor from "./Floor.js";
import Stars from "./Stars.js";
import Geo from "./Geo.js";
import Raycastable from "../UI/Raycastable.js";
import Grabbable from "../UI/Grabbable.js";
import TimeScrubber from "./TimeScrubber.js";
export default class World {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.floor = new Floor();
    this.scene.add(new THREE.AxesHelper());
    // Wait for resources
    this.resources.on("ready", () => {
      // console.log("resources ready"); // used to be used to debug when or if resources were ready
      this.stars = new Stars();
      this.geo = new Geo();
      this.environment = new Environment();
      // sample draggable object
      const grabbable = new TimeScrubber(
        new THREE.BoxGeometry(0.4, 0.15, 0.08),
        new THREE.MeshLambertMaterial({ color: 0xff0000 }),
        "grabbable"
      );
      console.log(grabbable.scale);
      grabbable.position.set(0, 0.5, 0);
      this.scene.add(grabbable);
    });
  }
  update() {} //unused
}
