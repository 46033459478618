import * as THREE from "three";
import Experience from "../../Experience";
import Calloutable from "../../UI/Calloutable";

export default class Agent extends Calloutable {
  constructor(
    geometry,
    material,
    name = "not named",
    color = 0xffff00,
    positions = [0, 0, 0],
    metadata = {}
  ) {
    super(geometry, material, name, color);
  }
}
